import React from "react";

import Theme from "../utils/theme";

import Layout from "../globals/index";
import Intro from "../components/intro";
import Mission from "../components/mission";
import Capabilities from "../components/capabilities";
import Contact from "../components/contact";

import { graphql } from 'gatsby';



const bgColor = Theme.Colors.cream;

class Index extends React.Component {

  constructor (props) {
    super(props)
    this.myRef = React.createRef()
    
  }

  render() {
    const {data} = this.props;
    
    return (
      <Layout>
        <Intro
          backgroundColor={bgColor}
          title="The Smithy"
          subTitle="Crafting branded content and experiences together"
          color={Theme.Colors.blue}
          scrollTarget={this.myRef}
        />
        <main>
          <Mission
            ref={this.myRef}
            headline="Who we are"
            text={Theme.Site.description}
          />
          <Capabilities 
            items={data.allCapabilitiesYaml.edges} 
          />
          <Contact 
            headline="Let's craft something together."
            buttonText="Contact Us"
            linkText="info@thesmithy.io"
            link="mailto:info@thesmithy.io?subject=I'm interested in crafting ________"
          />
        </main>
      </Layout>
    )
  }
}

export default Index


export const query = graphql`
  query getCapabilities {
    allCapabilitiesYaml {
      edges {
        node {
          name
          icon
          bullets
        }
      }
    }
  }
`;
