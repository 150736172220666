import React, { useLayoutEffect } from "react";

import styled from "styled-components";

import Theme from "../utils/theme";

import { ReactSVG } from 'react-svg'

// SET BG COLOR
const lightBlue = Theme.Colors.alphaMod(Theme.Colors.blue, "0.75");
const lightCream = Theme.Colors.cream;
const lightGrey = Theme.Colors.alphaMod(Theme.Colors.grey, "0.7");

// Create bulleted list for each card
function CreateList(props) {
  const items = props.items;
  const listItems = items.map(item => <li key={item}><p>{item}</p></li>);

  return <List>{listItems}</List>;
}

// Create each card within capabilities section
function CreateCard(props) {
  const details = props.item.node;

  return (
    <Card>
      <Figure>
        <ReactSVG className={details.name} src={details.icon}/>
      </Figure>
      <h3>{details.name}</h3>
      <CreateList items={details.bullets} />
    </Card>
  );
}

class Capabilities extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container>
        <div className="container">
          {this.props.items.map(item => (
            <CreateCard 
              key={item.node.name}
              item={item} 
            />
          ))}
        </div>
      </Container>
    );
  }
}

const Container = styled.section`
  background-color: ${lightBlue};
  padding: 4em 0;
  div.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
  }

  

  @media all and (min-width: 50em) {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    &:after {
      content:"";
      width: 100%;
      position: absolute;
      height: 15em;
      bottom: 0px;
      left: 0px;
      background-color: ${Theme.Colors.cream};
      z-index: 2;
    }
    div.container {
      width: 100%;
      flex-direction: row;
      align-items: stretch;
      box-shadow: 0px 10px 45px rgba(0,0,0, 0.3);
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      z-index: 3;
    }
  }
`;

const Card = styled.div`
  width: 90%;
  background-color: white;
  margin: 2em 0;
  box-shadow: 0px 10px 45px rgba(0,0,0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4em 0;
  border-radius: 4px;

  h3 {
    font-size: 2.8em;
    margin: 0.75em 0;
    color: ${Theme.Colors.blue};
    font-weight: 500;
    margin-top: 1em;
  }
  @media all and (min-width: 50em) {
    width:33.333333%;
    box-shadow: none;
    border-radius: 0px;
    margin: 0;
    position: relative;
    &:first-child,
    &:nth-child(2) {
      &:after {
        content:"";
        width: 1px;
        height: 60%;
        background-color: ${Theme.Colors.grey};
        position: absolute;
        opacity: 0.2;
        top:20%;
        right: 0px;
      }
    }
  }
`;


const List = styled.ul`

  li {
    margin: 2.5em 0;
  }
  p {
    font-size: 1.6em;
    font-weight: 700;
    text-align: center;
    color: ${lightGrey};
  }
`;

const Figure = styled.figure`
  width: 10em;
  height: 10em;
  background-color: ${lightCream};
  border-radius: 50%;
  /* box-shadow: 0px 0px 25px ${lightCream}; */
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  svg {
    max-width: 5em;
    height: auto;
    fill: ${Theme.Colors.blue};
  }

  div.Design svg {
    max-width: 4em;
  }
  div.Development {
    margin-top: .5em;
  }
  div.Content svg {
    max-width: 3em;
    margin-right: -0.25em;
    stroke: ${Theme.Colors.blue};
  }
`;

export default Capabilities;
