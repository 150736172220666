import React from "react";

import detectIt from 'detect-it';


import Icon from '../assets/svg/down-arrow.svg';
import Email from '../assets/svg/email.svg';

import styled from "styled-components";

import Theme from "../utils/theme";

const buttonShadow = Theme.Colors.alphaMod(Theme.Colors.orange, 0.25);

class LinkButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touchEvents: ''
    }
  }

  checkForTouch() {
    const touchClass =   detectIt.primaryInput === 'touch' ? 'touch-events' : '';
    this.setState({touchEvents: touchClass})
  }

  componentDidMount() {
    this.checkForTouch();
  }

  render() {
    return (
        <Button 
          onClick={this.props.onClick} 
          href={this.props.href} 
          className={`${this.state.touchEvents} ${this.props.className}`}
          style={{backgroundColor: this.props.backgroundColor, color: this.props.color}}>
          {this.props.arrow && (
            <Icon className="down-arrow"/>
          )}
          {this.props.email && (
            <Email className="email-icon"/>
          )}
          <span>{this.props.text}</span>
        </Button>
    );
  }
}

const Button = styled.a`
    text-transform: uppercase;
    padding: 0.75em 4em;
    font-size: 2em;
    font-weight: 900;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    svg {
      height: 1.25em;
      width: auto;
      fill: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(500%);
      transition: all 0.3s ease-in-out;
    }
    span {
      transition: all 0.3s ease-in-out;
      position: relative;
      left: 0%;
    }

  &:not(.touch-events) {
    &:hover {
      box-shadow: 0px 0px 25px ${buttonShadow};
      svg.down-arrow,
      svg.email-icon {
        transform: translateY(-50%) translateX(-50%);
      }
      svg.down-arrow + span,
      svg.email-icon + span {
        left: -300%;
      }
    }
  }
`;


export default LinkButton;
