import React from "react";

import styled from "styled-components";

import Button from './linkButton';

import Theme from "../utils/theme";
import { throws } from "assert";

const lightBlue = Theme.Colors.alphaMod(Theme.Colors.blue, "0.75");
const lightCream = Theme.Colors.cream;
const buttonColor = Theme.Colors.alphaMod(Theme.Colors.orange, 0.5);
const blueShadow = Theme.Colors.alphaMod(Theme.Colors.blue, 0.3);

class Contact extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Section>
        <div className="container">
          <h3>{this.props.headline}</h3>
          <Button 
            href={this.props.link}
            text={this.props.buttonText}
            backgroundColor={buttonColor}
            color="#FFF"
            email={true}
          />
          <a href={this.props.link}>{this.props.linkText}</a>
        </div>
      </Section>
    );
  }
}

const Section = styled.section`
  background-color: ${lightCream};
  padding: 2em 2em;
  padding-bottom: 6em;
  display: flex;
  justify-content: center;
  div.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
  }

  h3 {
    font-size: 2.5em;
    color: ${Theme.Colors.blue};
    font-weight: 700;
    letter-spacing: -0.5px;
    margin: 1em 0;
    line-height: 1.5em;
    text-shadow: 0px 5px 25px ${blueShadow};
  }

  a {
    color: ${Theme.Colors.orange};
    font-weight: 700;
    margin: 1.5em 0;
    text-align: center;
    text-decoration: none;
    
    margin-bottom: 0;
    padding: 0.75em 3em;
    &:last-child {
      margin-top: 1em;
      font-size: 2.4em;
    }
  }

  @media all and (min-width: 30em) {
    h3 {
      font-size: 3em;
    }
  }


  @media all and (min-width: 54em) {
    h3 {
      font-size: 5.8em;
    }
    p {
      font-size: 2.2em;
    }
  }
`;

export default Contact;
