import React from "react";

import styled from "styled-components";
import { scrollTo } from 'scroll-js';

import Theme from "../utils/theme";

const lightBlue = Theme.Colors.alphaMod(Theme.Colors.blue, "0.75");



class Mission extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  handleScrollToElement = (event) => {
    scrollTo(document.body, { top: this.myRef.current.offsetTop, duration: 800, easing: 'ease-in' });
  }

  render() {
    return (
      <Section ref={this.myRef}>
        <div className="container">
          <h3>{this.props.headline}</h3>
          <p>{this.props.text}</p>
        </div>
      </Section>
    );
  }
}

const Section = styled.section`
  background-color: ${lightBlue};
  padding: 4em 2em;
  padding-top: 8em;
  display: flex;
  justify-content: center;
  div.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
  }

  h3 {
    font-size: 5em;
    color: ${Theme.Colors.orange};
    font-weight: 700;
    letter-spacing: -0.5px;
  }

  p {
    color: #ffe9bf;
    font-weight: 700;
    margin: 1.5em 0;
    text-align: center;
  }

  @media all and (min-width: 50em) {
    h3 {
      font-size: 8em;
    }
    p {
      font-size: 2.2em;
    }
  }
`;

export default Mission;
