import React from "react";

import styled from "styled-components";

import Icon from '../assets/svg/Anvil-2.svg';
import Button from './linkButton';

import Theme from "../utils/theme";
import {isMobile} from 'react-device-detect';
import { useSpring, animated } from 'react-spring'



const Anvil = function(props) {

  const anvilAnimation = useSpring({ 
    to: [{ transform: 'translateY(0%))' },],
    from: { transform: 'translateY(-300%)' },
    config: {velocity: 20, friction: 14},
    delay: 300,
  })

  return (
    <animated.div style={anvilAnimation}><Icon className="logo"/></animated.div>
  )
}

const IntroText = function(props) {

  const params = props.props;
  const textAnimation = useSpring({ 
    to: [{ transform: 'translateY(0%))' },],
    from: { transform: 'translateY(-300%)' },
    config: {velocity: 10, friction: 18},
    delay: 750
  })

  return (
    <animated.div style={textAnimation}>
      <h1 style={{ color: params.color }}>{params.title}</h1>
      <p style={{ color: params.color }}>{params.subTitle}</p>
    </animated.div>
  )
}


const buttonColor = Theme.Colors.alphaMod(Theme.Colors.orange, 0.5);

const blueShadow = Theme.Colors.alphaMod(Theme.Colors.blue, 0.3);

class Intro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixedHeight: '',
      showButton: false
    }
  }

  handleClick = evt => {
    const { handleScrollToElement } = this.props.scrollTarget.current
    handleScrollToElement();
  }

  componentDidMount = () => {
    const height = window.innerHeight;

    if (isMobile) {
      this.setState({fixedHeight: height + 'px'})
    }
    setTimeout(() => {
      this.setState({ showButton: true });
    }, 1000);
  }


  render() {
    return (
      <Header style={{height: this.state.fixedHeight}}>
        <section>
          <IntroText props={this.props}/>
          <Anvil/>
          <Button
            text="Explore"
            backgroundColor={buttonColor}
            color="#FFF"
            onClick={this.handleClick}
            arrow={true}
            className={this.state.showButton && 'reveal'}
          />
        </section>
      </Header>
    );
  }
}

const Header = styled.header`
  width: 100%;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  background-color: ${Theme.Colors.cream};

  h1 {
    font-size: 5.6em;
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 0px 5px 25px ${blueShadow};
  }

  section {
    max-width: 50em;
    margin-top: 20vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
  }

  canvas {
    width: 100%;
    height: 100vh !important;
    z-index: -1;
  }

  p {
    font-weight: 500;
    font-style: italic;
    font-size: 1.4em;
    margin-top: 1.5em;
    text-align: center;
  }

  svg.logo {
    max-width: 17em;
    height: auto;
    margin-top: 0em;
    fill: ${Theme.Colors.blue};
    -webkit-filter: drop-shadow( 0px 5px 25px ${blueShadow});
    filter: drop-shadow( 0px 5px 25px ${blueShadow});
    width: 100%;
  }

  a {
    margin-top: 6em;
  }

  a {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    &.reveal {
      opacity: 1;
    }
  }
  @media all and (min-width: 43.75em) {
    
    h1 {
      font-size: 8.2em;
    }

    p {
      font-size: 1.8em;
    }

    svg.logo {
      max-width: 22em;
    }
    a {
      margin-top: 4em;
    }
  }
`;

export default Intro;
